<template>
  <div id="app">
    <router-view></router-view>
    <!-- <Login v-show="!$route.meta.isLogin"></Login> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style >

</style>