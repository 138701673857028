<template>

  <div class="cart_body">
    <Banner activeIndex="5"></Banner>

    <div class="content">
      <!-- 左侧菜单栏 -->
      <el-menu
        :default-openeds="['1', '3']"
        style="
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          width: 12%;
        "
        background-color="#1c1f26"
        text-color="#fff"
        active-text-color="#fff"
        :collapse-transition="false"
        :collapse="isCollapse"
        router
      >
        <el-menu-item index="/todayprice" style="background-color: #545c64">
          <template slot="title">
            <span class="bt" slot="title">全部分类</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/Notice" style="background-color: #431a14">
          <template slot="title">
            <span class="bt" slot="title">通知公告</span>
          </template>
        </el-menu-item>
        <el-menu-item style="height: 1000px"> </el-menu-item>
      </el-menu>
      <!-- 内容 -->
      <div class="table">
        <div class="contentt">
          <span class="shuxian">| </span>
          尊敬的客户:<br /><br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;您好！打款前请和对应业务联系。核对好银行账号之后，再打款！<br /><br /><br />
          谢谢配合！
        </div>
      </div>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import Banner from "../../components/Banner/Banner";
export default {
  data() {
    return {
      activeIndex2: "5",
    };
  },
  components: { Banner },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>

.contentt {
  margin-top: 20px;
  margin-left: 20px;
  color: black;
}

* {
  margin: 0;
  padding: 0;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.cart_body {
  width: 99%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
}
.table {
  width: 70%;
  height: 20%;
  margin: 100px 50px 200px 200px;
  background-color: white;
}

table {
  width: 100%;
  text-align: center;
}
table tr {
  height: 50px;
}
table tr td {
  border-top: 2px solid #ccc;
}

.content {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}

.bt{
  display: inline-block;
  margin-left: 30%;
}

.shuxian {
  font-weight: bolder;
  color: #ff5300;
  padding-top: -10px;
}
</style>