<template>
  <div class="box">
    <div class="title">新用户注册</div>
    <div class="center">
      <el-form label-width="120px" :rules="rules" :model="user">
        <el-form-item prop="user_tel" label="手机号">
          <el-input
            v-model="user.user_tel"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="user.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="merchant_name">
          <el-input
            v-model="user.merchant_name"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司联系人" prop="consignee_name">
          <el-input
            v-model="user.consignee_name"
            placeholder="请输入公司联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司联系人电话" prop="consignee_tel">
          <el-input
            v-model="user.consignee_tel"
            placeholder="请输入公司联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="consignee_address">
          <el-input
            v-model="user.consignee_address"
            placeholder="请输入公司地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="QQ/微信" prop="qq_wechat">
          <el-input placeholder="QQ/微信" v-model="user.qq_wechat"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="leader">
          <el-input placeholder="请输入负责人" v-model="user.leader"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="user_remark">
          <el-input
            autocomplete="off"
            v-model="user.user_remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card">
          <el-input
            autocomplete="off"
            v-model="user.id_card"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>

        <el-form-item label="推荐人" prop="referrer">
          <el-input
            autocomplete="off"
            v-model="user.referrer"
            placeholder="请输入推荐人"
          ></el-input>
        </el-form-item>

        <el-form-item label="营业执照">
          <el-upload
            :class="{ imgHide: imgHideUpload }"
            style="text-align: left"
            action="/file/upload"
            accept="JPG"
            :on-success="handleImgUploadSuccess"
            :on-preview="handlePreview"
            ref="my-upload"
          >
            <el-button size="mini" type="primary" plain>点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-image
          :src="'/file/' + license"
          class="fixed-size"
          v-show="show"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <el-form-item label="门头照">
          <el-upload
            :class="{ imgHide: imgHideUpload }"
            style="text-align: left"
            action="/file/upload"
            accept="JPG"
            :on-success="handleImgUploadSuccess1"
            :on-preview="handlePreview"
            ref="my-upload"
          >
            <el-button size="mini" type="primary" plain>点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-image
          :src="'/file/' + door_pic"
          class="fixed-size"
          v-show="show1"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </el-form>
      <button class="rg" @click="load()">注册</button>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Register",
  data() {
    return {
      imgHideUpload: true,
      door_pic: "1",
      license: "1",
      user: {},
      rules: {
        user_tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "长度11 个字符", trigger: "blur" },
        ],
        leader: [
          { required: true, message: "请输入负责人", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        referrer: [
          { required: true, message: "请输入推荐人", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        consignee_name: [
          { required: true, message: "请输入公司联系人名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        consignee_tel: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度11 个字符",
            trigger: "blur",
          },
        ],
        consignee_address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        merchant_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          { min: 1, max: 18, message: "长度1-50 个字符", trigger: "blur" },
        ],
        id_card: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          { min: 18, max: 18, message: "长度18 个字符", trigger: "blur" },
        ],
      },
      license: "",
      door_pic: "",
      show: false,
      show1: false,
    };
  },
  created() {},
  methods: {
    load() {
      console.log(this.user);
      axios({
        method: "POST",
        url: "/api/user/goreg",
        params: {
          user_tel: this.user.user_tel,
          password: this.user.password,
          merchant_name: this.user.merchant_name,
          qq_wechat: this.user.qq_wechat,
          leader: this.user.leader,
          consignee_name: this.user.consignee_name,
          consignee_tel: this.user.consignee_tel,
          consignee_address: this.user.consignee_address,
          user_remark: this.user_remark,
          id_card: this.user.id_card,
          referrer: this.user.referrer,
          door_pic: this.door_pic,
          license: this.license,
        },
      }).then((res) => {
        console.log(res.data.code);
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.$router.push("/login");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleImgUploadSuccess(res) {
      this.license = res;
      this.show = true;
    },
    handleImgUploadSuccess1(res) {
      this.door_pic = res;
      this.show1 = true;
    },
    handlePreview() {},
    upload() {},
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.box {
  width: 1100px;
  margin: 0 auto;
}
.title {
  margin-top: 15px;
  height: 50px;
  font-size: 25px;
  font-weight: bold;
}
.center {
  margin-top: 20px;
  margin-left: 50px;
}
.center input {
  width: 900px;
  height: 40px;
  padding-left: 5px;
}

.rg {
  width: 100px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  background-color: #409eff;
  border: 1px solid #409eff;
  border-radius: 5px;
  color: white;
  margin: 20px 500px;
}
.fixed-size {
  width: 200px;
  height: 200px;
}
/deep/ .imgHide .el-upload input {
  display: none !important;
}
</style>