<template>
  <div>
    <Banner></Banner>
    <div class="center">
      <div class="title">收货人信息确认</div>
      <el-form :rules="rules" :model="form" label-width="100px">
        <el-form-item prop="name" label="收货人姓名">
          <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="phone">
          <el-input placeholder="请输入电话" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="收货人地址" prop="address">
          <el-input placeholder="请输入地址" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="物流公司" prop="add">
          <el-input
            placeholder="请输入快递公司或者仓库自提"
            v-model="form.add"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单备注" prop="desc">
          <el-input
            type="textarea"
            v-model="form.desc"
            placeholder="订单备注"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="display: flex; justify-content: center; margin-top: 50px"
        >
        </el-form-item>
      </el-form>
      <button class="submit" @click="onSubmit">提交订单</button>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import Banner from "../../components/Banner/Banner.vue";
import axios from "axios";
export default {
  name: "SubOrder",
  components: { Banner },
  data() {
    return {
      form: { name: " ", phone: " ", address: " ", add: " ", desc: " " },
      name: "",
      phone: "",
      address: "",
      add: "",
      desc: "",

      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 1, max: 6, message: "长度1-6 个字符", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入地址", trigger: "blur" },
          {
            min: 5,
            max: 50,
            message: "长度在 5 到 50 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        add: [
          {
            required: true,
            message: "请输入快递公司或者仓库自提",
            trigger: "blur",
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getAddress();
  },
  methods: {
    onSubmit() {
      this.usertel = localStorage.users;
      console.log(this.usertel);
      axios({
        method: "POST",
        url: "/api/order/addOrder",
        params: {
          user_tel: this.usertel,
          consignee_name: this.form.name,
          consignee_tel: this.form.phone,
          consignee_address: this.form.address,
          logistics_company: this.form.add,
          order_remark: this.form.desc,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success("提交成功");
          this.$router.push("/myorder");
        } else {
          console.log(res);
        }
      });
    },
    getAddress() {
      this.usertel = localStorage.users;
      console.log(this.usertel);
      axios({
        method: "GET",
        url: "/api/address/addressList",
        params: {
          user_tel: this.usertel,
        },
      }).then((res) => {
        this.form.name = res.data[0].consignee_name;
        this.form.address = res.data[0].consignee_address;
        this.form.phone = res.data[0].consignee_tel;
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.center {
  width: 1200px;
  margin: 0 auto;
}
.title {
  width: 100%;
  height: 100px;
  padding-top: 30px;
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
}
.submit {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
  border-radius: 5px;
  font-size: 15px;
}
</style>