// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
import MyOrder from '../pages/Myorder/MyOrder'
import ShoppingCart from '../pages/ShoppingCart/ShoppingCart'
import Login from '../pages/Login/Login'
import Todayprice from '../pages/Todayprice/Todayprice'
import Notice from '../pages/Notice/Notice'
import Mydata from '../pages/Mydata/Mydata'
import Register from '../pages/Register/intex'
import SubOrder from '../pages/SubOrder/SubOrder'



const router = new VueRouter({
    mode: 'history',
    base: '',
    routes: [

        {
            path: '*',
            redirect: '/login'
        },
        // {
        //     path:'/newtodayprice',
        //     component:Newtodayprice
        // },
        // {
        //     path:'/demo',
        //     component:Demo
        // },

        {
            path: '/login',
            component: Login,
            meta: { title: '登录' },

        },
        {
            path: '/myorder',
            component: MyOrder,
            meta: { title: '我的订单' },


        },
        {
            path: '/shoppingcart',
            component: ShoppingCart,
            meta: { title: '购物车' },

        },
        {
            path: '/todayprice',
            component: Todayprice,
            meta: { title: '今日报价' },

        },
        {
            path: '/notice',
            component: Notice,
            meta: { title: '通知公告' },


        },
        {
            path: '/mydata',
            component: Mydata,
            meta: { title: '我的资料' },


        },
        {
            path: '/Register',
            component: Register,
            meta: { title: '免费注册' },


        },
        {
            path: '/SubOrder',
            component: SubOrder,
            meta: { title: '收货人信息确认' },


        },
    ]

})

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
    console.log('前置路由守卫', to, from)
    document.title = to.meta.title
    next()
})

export default router;