<template>
  <div class="MyOrder_body">
    <Banner activeIndex="4"></Banner>

    <div class="content">
      <!-- 左侧菜单栏 -->
      <el-menu
        :default-openeds="['1', '3']"
        style="
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          width: 12%;
        "
        background-color="#1c1f26"
        text-color="#fff"
        active-text-color="#fff"
        :collapse-transition="false"
        :collapse="isCollapse"
        router
      >
        <el-menu-item index="/todayprice" style="background-color: #545c64">
          <template slot="title">
            <span class="bt" slot="title">全部分类</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/myorder" style="background-color: #431a14">
          <template  slot="title">
            <span class="bt" slot="title">我的订单</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/myorder" style="height: 1000px"> </el-menu-item>
      </el-menu>
      <!-- 表格 -->
      <div class="table">
        <div class="block">
          <!-- 订单日期 -->
          <div class="select" style="line-height: 60px">
            <span style="display: inline-block; width: 80px; text-align: center"
              >订单日期</span
            >
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <!-- 订单状态 -->
            <span style="margin-left: 30px; margin-right: 10px">订单状态</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <button class="inquire" @click="chaxun">查询</button>
          </div>

          <span style="color: red; display: inline-block; float: right">
            说明：点击单据号可以查看订单详情！<br />点击未支付可以进行支付！
          </span>
        </div>

        <table>
          <tr style="font-weight: bold">
            <td rowspan="2">订单状态</td>
            <td rowspan="2">单据号</td>
            <td rowspan="2">订单日期</td>
            <td colspan="2">订货</td>
            <td colspan="2">确认</td>
            <td rowspan="2">备注</td>
            <td rowspan="2">物流信息</td>
            <td rowspan="2">收货人</td>
            <td rowspan="2">电话</td>
            <td rowspan="2">地址</td>
          </tr>
          <tr style="font-weight: bold">
            <td>数量</td>
            <td>金额（元）</td>
            <td>数量</td>
            <td>金额（元）</td>
          </tr>
          <template v-for="item in tableData">
            <tr>
              <td>
                <el-button
                  type="text"
                  @click="todetail11(item)"
                  v-if="item.order_status === 0"
                >
                  未确认
                </el-button>
                <el-button
                  type="text"
                  @click="todetail11(item)"
                  v-if="item.order_status === 1"
                >
                  配货中
                </el-button>
                <el-button
                  type="text"
                  @click="todetail11(item)"
                  v-if="item.order_status === 2"
                >
                  已确认
                </el-button>
                <el-button
                  type="text"
                  @click="todetail11(item)"
                  v-if="item.order_status === 3"
                >
                  待发货
                </el-button>
                <el-button
                  type="text"
                  @click="todetail11(item)"
                  v-if="item.order_status === 4"
                >
                  已发货
                </el-button>
              </td>
              <td>
                <el-button type="text" @click="todetail(item)">
                  {{ item.order_num }}
                </el-button>
              </td>
              <td>{{ item.order_time }}</td>
              <td>{{ item.commodity_amount }}</td>
              <td>{{ item.commodity_price }}</td>
              <td>{{ item.commodity_amount }}</td>
              <td>{{ item.commodity_price }}</td>
              <td>{{ item.order_remark }}</td>
              <td>{{ item.logistics_company }}</td>
              <td>{{ item.consignee_name }}</td>
              <td>{{ item.consignee_tel }}</td>
              <td>{{ item.consignee_address }}</td>
              <td>
                <el-button type="text" @click="dialogVisible3 = true">
                  {{ item.affirm }}
                </el-button>
              </td>
              <td>
                <el-button type="text" @click="dialogVisible4 = true">
                  {{ item.evaluate }}
                </el-button>
              </td>
            </tr>
          </template>
          <tr style="border-bottom: 1px solid #ccc">
            <td>总 计</td>
            <td></td>
            <td></td>
            <td>{{ this.accnum }}</td>
            <td>{{ this.accpri }}</td>
            <td>{{ this.accnum }}</td>
            <td>{{ this.accpri }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <!-- 模态框1:订单状态 -->
        <el-dialog
          title="串号明细"
          :visible.sync="dialogVisible1"
          width="50%"
          :before-close="handleClose"
        >
          <div>
            <table>
              <tr style="font-weight: bold">
                <td style="width: 15%">订单日期</td>
                <td style="width: 30%">商品名称</td>
                <td style="width: 10%">数量</td>
                <!-- <td style="width: 40%">串号</td> -->
              </tr>
              <template v-for="a in douNumbers">
                <tr>
                  <td style="width: 15%">{{ a.order_time }}</td>
                  <td style="width: 30%">{{ a.commodity_name }}</td>
                  <td style="width: 10%">{{ a.commodity_amount }}</td>
                  <!-- <td style="width: 40%">{{ a.IMEI }}</td> -->
                </tr>
              </template>
            </table>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="btn" @click="dialogVisible1 = false">关 闭</button>
          </span>
        </el-dialog>

        <!-- 模态框2：单据号 -->
        <el-dialog
          title="订单明细"
          :visible.sync="dialogVisible2"
          width="70%"
          hight="50"
          :before-close="handleClose"
        >
          <div>
            <table>
              <tr style="height: 40px">
                <td class="first">收货人姓名</td>
                <td style="text-align: left">
                  {{ tableData1.consignee_name }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td class="first">收货人联系电话</td>
                <td style="text-align: left">{{ tableData1.consignee_tel }}</td>
              </tr>
              <tr style="height: 40px">
                <td class="first">收货人地址</td>
                <td style="text-align: left">
                  {{ tableData1.consignee_address }}
                </td>
              </tr>
              <!-- <tr style="height: 40px;">
              <td class="first">业务经理</td>
              <td style="text-align: left;">祝晓晶</td>
            </tr> -->
              <tr style="height: 40px">
                <td class="first">订单备注</td>
                <td style="text-align: left">{{ tableData1.order_remark }}</td>
              </tr>
              <tr style="height: 40px">
                <td class="first" rowspan="2">订货信息</td>
                <td style="text-align: left">
                  订货数量：{{ tableData1.commodity_amount }} 订货金额：￥{{
                    tableData1.commodity_price
                  }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="text-align: left; border-top: 0">
                  确认数量：<span style="color: red">{{
                    tableData1.commodity_amount
                  }}</span>
                  确认金额：<span style="color: red"
                    >￥{{ tableData1.commodity_price }}</span
                  >
                </td>
              </tr>
              <tr style="height: 40px">
                <td class="first">物流信息</td>
                <td style="text-align: left">
                  物流金额：￥0 物流公司：{{
                    tableData1.logistics_company
                  }}
                  物流单号：{{ tableData1.logistics_num }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td class="first">应付款信息</td>
                <td style="text-align: left">
                  物流金额：￥0 维修费：￥0 其他金额：￥0 应付金额：￥{{
                    tableData1.commodity_price
                  }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td class="first">付款信息</td>
                <td style="text-align: left">
                  名称：河南众知物科技有限公司
                  付款银行：中国建设银行股份有限公司
                  付款账号：41050166670800000591 付款金额：{{
                    tableData1.commodity_price
                  }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td class="first">状态</td>
                <td
                  style="color: red; text-align: left"
                  v-if="tableData1.order_status === 0"
                >
                  未确认
                </td>
                <td
                  style="color: red; text-align: left"
                  v-if="tableData1.order_status === 1"
                >
                  配货中
                </td>
                <td
                  style="color: red; text-align: left"
                  v-if="tableData1.order_status === 2"
                >
                  已确认
                </td>
                <td
                  style="color: red; text-align: left"
                  v-if="tableData1.order_status === 3"
                >
                  待发货
                </td>
                <td
                  style="color: red; text-align: left"
                  v-if="tableData1.order_status === 4"
                >
                  已发货
                </td>
              </tr>
            </table>

            <table style="margin-top: 30px">
              <tr style="font-weight: bold; height: 40px">
                <td>订单日期</td>
                <td>商品名</td>
                <td>订购数量</td>
                <td>订购单价</td>
                <td>订购金额</td>
                <td>确认数量</td>
                <td>确认单价</td>
                <td>确认金额</td>
              </tr>
              <template v-for="item in douNumbers1">
                <tr>
                  <td>{{ item.order_time }}</td>
                  <td>{{ item.commodity_name }}</td>
                  <td>{{ item.commodity_amount }}</td>
                  <td>{{ item.commodity_price }}</td>
                  <td>{{ item.commodity_total_price }}</td>
                  <td>{{ item.commodity_amount }}</td>
                  <td>{{ item.commodity_price }}</td>
                  <td>{{ item.commodity_total_price }}</td>
                </tr>
              </template>
            </table>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="btn" @click="dialogVisible2 = false">关 闭</button>
          </span>
        </el-dialog>

        <!-- 模态框3：确认打款 -->
        <el-dialog
          title="确认打款"
          :visible.sync="dialogVisible3"
          width="60%"
          :before-close="handleClose"
        >
          <div>
            <table>
              <tr>
                <td rowspan="2" style="width: 20%">汇款登记</td>

                <td style="text-align: right; padding-right: 5px">
                  填写银行：
                </td>

                <td>
                  <select
                    name=""
                    id=""
                    style="
                      border: 1px solid #000;
                      width: 150px;
                      height: 40px;
                      border-radius: 5px;
                    "
                  >
                    <option value="">请选择打款银行</option>
                    <option value="">中国农业银行</option>
                    <option value="">中国建设银行</option>
                  </select>
                </td>
                <td style="width: 10%; text-align: right; padding-right: 5px">
                  银行账号：
                </td>
                <td style="width: 25%">
                  <input type="text" class="inp" />
                </td>
                <td style="width: 10%; text-align: right; padding-right: 5px">
                  填写金额：
                </td>
                <td style="width: 25%">
                  <input type="text" class="inp" />
                </td>
              </tr>

              <tr>
                <td
                  style="border-top: 0; text-align: right; padding-right: 5px"
                >
                  填写备注：
                </td>
                <td colspan="3" style="width: 45%; border-top: 0">
                  <input type="text" class="inp" />
                </td>
                <td style="width: 10%; border-top: 0">
                  <button
                    style="
                      width: 100px;
                      height: 40px;
                      background-color: #c8161d;
                      border-radius: 5px;
                      color: white;
                    "
                  >
                    确认打款
                  </button>
                </td>
                <td style="border-top: 0"></td>
              </tr>
              <!-- 第二行 -->
              <tr>
                <td rowspan="2" style="border-bottom: 1px solid #ccc">
                  上传汇款单
                </td>
                <td>
                  <input
                    type="button"
                    value="选择文件"
                    style="
                      width: 80px;
                      height: 35px;
                      border: 1px solid #000;
                      background-color: #ccc;
                      border-radius: 5px;
                    "
                  />
                </td>
                <td style="text-align: left; padding-left: 5px">未选择文件</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="
                    border-top: 0;
                    font-weight: bold;
                    border-bottom: 1px solid #ccc;
                    color: red;
                    text-align: left;
                  "
                >
                  只能上传jpg,png,gif格式的图片
                </td>
                <td
                  style="
                    border-top: 0;
                    border-bottom: 1px solid #ccc;
                    text-align: left;
                  "
                >
                  <input
                    type="button"
                    value="上传"
                    style="
                      width: 60px;
                      height: 40px;
                      background-color: #2486b9;
                      border-radius: 5px;
                      color: white;
                    "
                  />
                </td>
                <td style="border-top: 0; border-bottom: 1px solid #ccc"></td>
                <td style="border-top: 0; border-bottom: 1px solid #ccc"></td>
                <td style="border-top: 0; border-bottom: 1px solid #ccc"></td>
              </tr>
            </table>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible3 = false">
              关 闭
            </el-button>
          </span>
        </el-dialog>

        <!-- 模态框4：评价 -->
        <el-dialog
          title="评价信息"
          :visible.sync="dialogVisible4"
          width="20%"
          :before-close="handleClose"
        >
          <div>
            <el-radio v-model="radio" label="1">好评</el-radio>
            <el-radio v-model="radio" label="2">中评</el-radio>
            <el-radio v-model="radio" label="3">差评</el-radio>
          </div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            style="border: 1px solid #555; margin-top: 30px"
          ></textarea>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible4 = false">
              提交
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import Banner from "../../components/Banner/Banner";
import axios from "axios";
export default {
  name: "MyOrder",
  components: { Banner },
  data() {
    return {
      accnum: 0,
      accpri: 0,
      activeIndex: "4",
      radio: "1",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      value: "全部",
      value1: "",
      value2: "",
      options: [
        {
          value: 5,
          label: "全部",
        },
        {
          value: 0,
          label: "未确认",
        },
        {
          value: 1,
          label: "配货中",
        },
        {
          value: 2,
          label: "已确认",
        },
        {
          value: 3,
          label: "待发货",
        },
        {
          value: 4,
          label: "已发货",
        },
      ],
      tableData: [{ status1: "" }],
      douNumbers: [],
      douNumbers1: [],
      usertel: "",
      tableData1: {},
      ordernum: "",
    };
  },
  created() {
    this.load();
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    load() {
      this.usertel = localStorage.users;
      axios({
        method: "GET",
        url: "/api/order/orderList",
        params: {
          user_tel: this.usertel,
        },
      }).then((res) => {
        console.log(res);
        this.tableData = res.data;
        let sum = 0;
        let sum1 = 0;
        this.tableData.forEach((item) => {
          //遍历prodAllPrice这个字段，并累加
          sum += item.commodity_price;
          sum1 += item.commodity_amount;
        });
        //返回
        this.accpri = sum;
        this.accnum = sum1;
      });
    },
    todetail(item) {
      this.dialogVisible2 = true;
      this.tableData1 = item;
      this.ordernum = item.order_num;
      axios({
        method: "GET",
        url: "/api/order/orderByNum",
        params: {
          order_num: this.ordernum,
        },
      }).then((res) => {
        console.log(res);
        this.douNumbers1 = res.data.orderCommodityList;
      });
    },
    todetail11(item) {
      console.log(item);
      this.dialogVisible1 = true;
      this.ordernum = item.order_num;
      axios({
        method: "GET",
        url: "/api/order/orderByNum",
        params: {
          order_num: this.ordernum,
        },
      }).then((res) => {
        console.log(res);
        this.douNumbers = res.data.orderCommodityList;
      });
    },
    //查询
    chaxun() {
      this.usertel = localStorage.users;
      console.log(this.value);
      console.log(this.value1);
      if (this.value === 5) {
        this.usertel = localStorage.users;
        axios({
          method: "GET",
          url: "/api/order/orderList",
          params: {
            user_tel: this.usertel,
          },
        }).then((res) => {
          console.log(res);
          this.tableData = res.data;
          let sum = 0;
          let sum1 = 0;
          this.tableData.forEach((item) => {
            //遍历prodAllPrice这个字段，并累加
            sum += item.commodity_price;
            sum1 += item.commodity_amount;
          });
          //返回
          this.accpri = sum;
          this.accnum = sum1;
        });
      } else {
        if (!this.value1) {
          this.$message.error("请选择日期");
        } else {
          axios({
            method: "GET",
            url: "/api/order/orderByTimeOrStatus",
            params: {
              user_tel: this.usertel,
              start_time: this.value1[0],
              end_time: this.value1[1],
              order_status: this.value,
            },
          }).then((res) => {
            console.log(res);
            this.tableData = res.data;
            let sum = 0;
            let sum1 = 0;
            this.tableData.forEach((item) => {
              //遍历prodAllPrice这个字段，并累加
              sum += item.commodity_price;
              sum1 += item.commodity_amount;
            });
            //返回
            this.accpri = sum;
            this.accnum = sum1;
          });
        }
      }
    },
  },
};
</script>


<style scoped>
* {
  margin: 0px;
  padding: 0px;
  border: none;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.MyOrder_body {
  width: 100%;
  margin: 0 auto;
}
.bar_top {
  width: 100%;
  height: 50px;
  display: flex;
  font-size: 20px;
  line-height: 50px;
}
.MyOrder_top {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-color: #545c64;
  color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
}
.MyOrder_top img {
  height: 35px;
  width: 35px;
  line-height: 35px;
  margin: 10px 5px 0 10px;
}
.block {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
table {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}
table tr {
  height: 70px;
}
table tr td {
  border-top: 2px solid #ccc;
}
/* 模态框 */
/* 点击弹出遮罩层部分样式 */
.login_header {
  width: 100%;
  text-align: center;
}
.inp {
  border: 1px solid #ccc;
  height: 40px;
  width: 100%;
  border-radius: 5px;
}
.first {
  width: 20%;
  text-align: left;
  padding-left: 10px;
}
.inquire {
  width: 70px;
  height: 40px;
  margin-left: 20px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
  border-radius: 5px;
  font-size: 15px;
}
.btn {
  width: 100px;
  height: 40px;
  margin-left: 20px;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: #429ed0;
  border: 1px solod #429ed0;
  border-radius: 5px;
  font-size: 15px;
}
.content {
  display: flex;
  justify-content: flex-start;
}
.bt{
  display: inline-block;
  margin-left: 30%;
}
</style>
