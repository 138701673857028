<template>
  <!-- 顶部导航栏 -->
  <div class="line">
    <!-- <div class="line"></div> -->
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#1c1f26"
      text-color="#fff"
      active-text-color="#fa4100"
    >
      <div>
        <img
          src="../Banner/images/1.png"
          style="
            width: 45px;
            height: 45px;
            float: left;
            margin-left: 50px;
            margin-top: 8px;
          "
        />
        <span
          style="
            float: left;
            color: white;
            margin-left: 10px;
            padding-top: 18px;
            margin-right: 30px;
          "
          >欢迎！</span
        >
      </div>
      <el-menu-item index="1" @click="tojinribaojia">
        <span style="text-decoration: none" @click="tojinribaojia"
          >今日报价</span
        >
      </el-menu-item>
      <el-menu-item index="2" @click="towode">
        <!-- <router-link active-class="active" to="/mydata" style="text-decoration:none">我的资料</router-link> -->
        <span style="text-decoration: none" @click="towode">我的资料</span>
      </el-menu-item>
      <el-menu-item index="3" @click="tocar">
        <!-- <router-link active-class="active" to="/shoppingcart" style="text-decoration:none">购物车</router-link> -->
        <span style="text-decoration: none" @click="tocar">购物车</span>
      </el-menu-item>
      <el-menu-item index="4" @click="tomyorder">
        <!-- <router-link active-class="active" to="/myorder" style="text-decoration:none">我的订单</router-link> -->
        <span style="text-decoration: none" @click="tomyorder">我的订单</span>
      </el-menu-item>
      <el-menu-item index="5" @click="totongzhi">
        <!-- <router-link active-class="active" to="/notice" style="text-decoration:none">通知公告</router-link> -->
        <span style="text-decoration: none" @click="totongzhi">通知公告</span>
      </el-menu-item>

      <span
        ><img
          src="./images/logo.png"
          style="
            width: 50px;
            height: 50px;
            margin-left: 800px;
            margin-top: 3px;
          "
      /></span>
      <span class="loginout"
        >业务员为：{{ user }}， <a @click="tuichu">退出登录</a></span
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["activeIndex"],

  data() {
    return {
      user: "美羊羊",
    };
  },
  created() {
    this.load();
  },
  methods: {
    tojinribaojia() {
      this.$router.push("/todayprice");
    },
    towode() {
      this.$router.push("/mydata");
    },
    tocar() {
      this.$router.push("/shoppingcart");
    },
    tomyorder() {
      this.$router.push("/myorder");
    },
    totongzhi() {
      this.$router.push("/notice");
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleOpen(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleClose(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    load() {
      this.user = localStorage.users;
    },
    tuichu() {
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.loginout {
  margin-left: 20px;
  color: white;
  line-height: 10px;
}
.loginout a {
  /* text-decoration: underline; */
  color: rgb(153, 149, 149);
}
</style>