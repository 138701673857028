<template>
  <div class="box">
    <Banner activeIndex="2"></Banner>
    <!-- 我的资料 -->

    <div class="Data_Content">
      <!-- 左侧菜单栏 -->
      <el-menu
        :default-openeds="['1', '3']"
        style="
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          width: 12%;
        "
        background-color="#1c1f26"
        text-color="#fff"
        active-text-color="#fff"
        :collapse-transition="false"
        :collapse="isCollapse"
        router
      >
        <el-menu-item index="/todayprice" style="background-color: #545c64">
          <template slot="title">
            <span class="bt" slot="title">全部分类</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/mydata" style="background-color: #431a14">
          <template slot="title">
            <span class="bt" slot="title">我的资料</span>
          </template>
        </el-menu-item>
        <el-menu-item style="height: 1000px"> </el-menu-item>
      </el-menu>

      <!-- 内容部分 -->
      <div class="content">
        <div class="one"></div>
        <div class="demo">
          <div>
            <el-button
              type="info"
              plain
              @click="showModal"
              style="font-size: 12px; line-height: 3px"
              >新增收货地址</el-button
            >
          </div>
          <div>
            <el-button
              type="info"
              plain
              @click="showMsg"
              style="font-size: 12px; line-height: 3px"
              >收货信息管理</el-button
            >
          </div>
          <div>
            <el-button
              type="info"
              @click="showPswd"
              style="font-size: 12px; line-height: 3px"
              >修改密码</el-button
            >
          </div>
          <div>
            <el-button type="info" style="font-size: 12px; line-height: 3px"
              >订单图标设置</el-button
            >
          </div>
        </div>

        <!-- 四个按钮 -->
        <div>
          <!-- 新增收货地址 -->
          <template>
            <!-- <el-button type="info" plain @click="showModal">新增收货地址</el-button> -->
            <div v-if="isModalVisible" class="modal">
              <div class="modal-content">
                <div
                  style="
                    font-size: 25px;
                    font-weight: bolder;
                    margin-bottom: 10px;
                  "
                >
                  添加信息
                </div>
                <div>
                  <label for="name">姓名:</label>
                  <input type="text" id="name" v-model="name1" />
                </div>
                <div>
                  <label for="phone">电话:</label>
                  <input type="text" id="phone" v-model="phone1" />
                </div>
                <!-- 选择地址 -->
                <div>
                  <label>地址：</label>
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="selectedOptions1"
                    @change="handleChange"
                    style="
                      margin-left: 20px;
                      width: 310px;
                      margin-top: 10px;
                      margin-bottom: 10px;
                    "
                  >
                  </el-cascader>
                </div>

                <!-- 详细地址 -->
                <div>
                  <label for="name">地址:</label>
                  <input
                    type="text"
                    id="address"
                    v-model="address1"
                    placeholder="请输入详细地址"
                  />
                </div>

                <div>
                  <el-button
                    type="primary"
                    @click="save"
                    style="
                      margin-left: 150px;
                      line-height: 5px;
                      margin-top: 20px;
                    "
                    >保存</el-button
                  >
                  <el-button @click="closeModal" style="line-height: 5px"
                    >关闭</el-button
                  >
                </div>
              </div>
            </div>
          </template>

          <!-- 收货信息管理 -->
          <template>
            <!-- <el-button type="info" plain @click="showMsg">收获信息管理</el-button> -->
            <div v-if="isMsgVisible" class="modal">
              <div class="modal2-content">
                <div>收货地址列表</div>
                <hr />
                <template>
                  <el-table :data="tableData" style="width: 100%" height="250">
                    <!-- <el-table-column prop="address_id" label="序号" width="120">
                </el-table-column> -->
                    <el-table-column
                      prop="consignee_name"
                      label="收货人"
                      width="120"
                    >
                    </el-table-column>

                    <el-table-column
                      prop="consignee_tel"
                      label="收货人电话"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="consignee_address"
                      label="收货人地址"
                      width="300"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="default_address"
                      label="是否默认"
                      width="120"
                    >
                    </el-table-column>
                    <!-- <el-table-column fixed="right" label="操作" width="100">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          size="small"
                          @click="showModal(scope.row)"
                          >编辑</el-button
                        >

                        <el-button
                          @click.native.prevent="deleteRow(scope.row)"
                          type="text"
                          size="small"
                        >
                          移除
                        </el-button>
                      </template>
                    </el-table-column> -->
                  </el-table>
                </template>

                <!-- 弹出的页面内容 -->
                <div v-if="isModalVisible">
                  <template>
                    <!-- <el-button type="info" plain @click="showModal">新增收货地址</el-button> -->
                    <div v-if="isModalVisible" class="modal">
                      <div class="modal-content">
                        <div
                          style="
                            font-size: 25px;
                            font-weight: bolder;
                            margin-bottom: 10px;
                          "
                        >
                          添加信息
                        </div>
                        <div>
                          <label for="name">姓名:</label>
                          <input type="text" id="name" v-model="name" />
                        </div>
                        <div>
                          <label for="phone">电话:</label>
                          <input type="text" id="phone" v-model="phone" />
                        </div>
                        <!-- 选择地址 -->
                        <div>
                          <label>地址：</label>
                          <el-cascader
                            size="large"
                            :options="options"
                            v-model="selectedOptions"
                            @change="handleChange"
                            style="
                              margin-left: 20px;
                              width: 310px;
                              margin-top: 10px;
                              margin-bottom: 10px;
                            "
                          >
                          </el-cascader>
                        </div>

                        <!-- 详细地址 -->
                        <div>
                          <label for="name">地址:</label>
                          <input
                            type="text"
                            id="address"
                            v-model="address"
                            placeholder="请输入详细地址"
                          />
                        </div>

                        <div>
                          <el-button
                            type="primary"
                            @click="showModal1"
                            style="
                              margin-left: 260px;
                              line-height: 5px;
                              margin-top: 20px;
                            "
                            >保存</el-button
                          >
                          <el-button
                            @click="closeModal"
                            style="line-height: 5px"
                            >关闭</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <div>
                  <el-button @click="closeMsg" class="btn2">关闭</el-button>
                </div>
              </div>
            </div>
          </template>

          <!-- 修改密码 -->
          <template>
            <div v-if="isPswdVisible" class="modal">
              <div class="modal-content1">
                <div
                  style="
                    font-size: 25px;
                    font-weight: bolder;
                    margin-bottom: 10px;
                    margin-top: 5px;
                  "
                >
                  修改密码
                </div>

                <div>
                  <label for="oldPswd">输入旧密码</label>
                  <input type="password" id="oldPswd" v-model="oldPswd" />
                </div>
                <div>
                  <label for="newPswd">输入新密码</label>
                  <input type="text" id="newPswd" v-model="newPswd" />
                </div>
                <div>
                  <label for="renewPswd">输入手机号</label>
                  <input type="text" id="renewPswd" v-model="renewPswd" />
                </div>

                <div>
                  <el-button
                    type="primary"
                    @click="update"
                    style="
                      margin-top: 5px;
                      margin-left: 200px;
                      line-height: 5px;
                    "
                    >修改</el-button
                  >
                  <el-button
                    @click="closePswd"
                    style="margin-top: 5px; line-height: 5px"
                    >关闭</el-button
                  >
                </div>
              </div>
            </div>
          </template>

          <!-- 订单图标设置 -->
          <!-- <el-button type="info">订单图标设置</el-button> -->
        </div>

        <!-- 四个按钮下面的东西 -->
        <div class="center">
          <div>
            <label for="phonenumber">手机号/B2B账号&nbsp;&nbsp;</label>
            <input type="text" id="phonenumber" v-model="tableData1.user_tel" />
          </div>
          <div>
            <label for="merchant"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商家名称&nbsp;&nbsp;</label
            >
            <input
              type="text"
              id="merchant"
              v-model="tableData1.merchant_name"
            />
          </div>
          <div>
            <label for="social"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QQ/微信&nbsp;&nbsp;</label
            >
            <input type="text" id="social" v-model="tableData1.qq_wechat" />
          </div>
          <div>
            <label for="admin"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;负责人&nbsp;&nbsp;</label
            >
            <input type="text" id="admin" v-model="tableData1.leader" />
          </div>
          <div>
            <label for="remark"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备注&nbsp;&nbsp;</label
            >
            <input
              type="text"
              id="remark"
              v-model="tableData1.user_remark"
              placeholder="备注"
            />
          </div>
        </div>
      </div>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import Banner from "../../components/Banner/Banner.vue";
import { regionData } from "element-china-area-data";
import axios from "axios";

export default {
  components: { Banner },
  data() {
    return {
      addressId: 0,
      activeIndex: "2",
      // activeIndex2: "2",
      address: "",
      isModalVisible: false,
      isMsgVisible: false,
      isPswdVisible: false,
      isBianji: false,
      name: "",
      phone: "",
      // selectedProvince: "",
      // selectedCity: "",
      // selectedDistrict: "",
      // provinces: ["省份1", "省份2", "省份3"], // 省份数据
      // cities: [], // 城市数据
      // districts: [], // 区县数据
      phonenumber: "",
      merchant: "",
      social: "",
      admin: "",
      remark: "",
      oldPswd: "",
      newPswd: "",
      renewPswd: "",
      getmessage: false,
      tableData1: [],
      options: regionData,
      selectedOptions: [],
      tableData: [],
      userpassword: {},
      default_address: 0,
      default_address1: 0,
      name1: "",
      phone1: "",
      selectedOptions: "",
      address1: "",
    };
  },
  created() {
    this.load();
  },
  methods: {
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      console.log(loc); //打印区域码所对应的属性值即中文地址
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    showModal(res) {
      this.isModalVisible = true;

      this.addressId = res.address_id;
      this.default_address = res.default_address;
    },
    showModal1() {
      this.isModalVisible = true;

      axios({
        method: "POST",
        url: "/api/address/updateAddress",
        params: {
          address_id: this.addressId,
          consignee_name: this.name,
          consignee_tel: this.phone,
          consignee_address: this.selectedOptions + this.address,
          default_address: this.default_address,
        },
      }).then((res) => {
        this.showMsg();
        this.$message.success("更新成功");
        this.isModalVisible = false;
      });
    },
    showMsg() {
      this.isMsgVisible = true;
      this.usertel = localStorage.users;

      axios({
        method: "GET",
        url:
          "/api/address/addressList?user_tel=" +
          this.usertel,
      }).then((res) => {
        this.tableData = res.data;
      });
    },
    showPswd() {
      this.isPswdVisible = true;
    },
    showbianji() {
      this.isBianji = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeMsg() {
      this.isMsgVisible = false;
    },
    closePswd() {
      this.isPswdVisible = false;
    },

    onProvinceChange() {
      // 根据选择的省份更新城市数据
      // 可以根据实际情况进行异步请求获取对应城市数据
      this.cities = ["城市1", "城市2", "城市3"];
      this.selectedCity = "";
      this.districts = [];
      this.selectedDistrict = "";
    },
    onCityChange() {
      // 根据选择的城市更新区县数据
      // 可以根据实际情况进行异步请求获取对应区县数据
      this.districts = ["区县1", "区县2", "区县3"];
      this.selectedDistrict = "";
    },
    save() {
      // 点击保存按钮的逻辑，可以将输入的数据进行处理或提交到后端
      this.usertel = localStorage.users;
      axios({
        method: "GET",
        url: "/api/address/addAddress",
        params: {
          user_tel: this.usertel,
          consignee_name: this.name1,
          consignee_tel: this.phone1,
          consignee_address: this.selectedOptions1 + this.address1,
          default_address: this.default_address1,
        },
      }).then((res) => {
        console.log(res);
        this.$message.success("保存成功");
      });
      this.isModalVisible = false;
    },
    deleteRow(res) {
      this.addressId = res.address_id;
      axios({
        method: "GET",
        url:
          "/api/address/deleteAddress?address_id=" +
          this.addressId,
      }).then((res) => {
        this.$message.success("删除成功");
        this.showMsg();
      });
    },
    load() {
      this.usertel = localStorage.users;
      console.log(this.usertel);
      axios({
        method: "GET",
        url:
          "/api/user/userinfo?user_tel=" + this.usertel,
      }).then((res) => {
        this.tableData1 = res.data;
      });
    },
    update() {
      axios({
        method: "POST",
        url: "/api/user/updatePassword",
        params: {
          current_password: this.oldPswd,
          new_password: this.newPswd,
          user_tel: this.renewPswd,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.$router.push("/login");
          this.isPswdVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

 <style scoped>
.box {
  width: 99%;
  margin: 0 auto;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.mymsg {
  width: 100%;
  height: 50px;
  background-color: #545c64;
  color: aliceblue;
  font-size: 25px;
  border: 1px solid #545c64;
  margin: 10px auto;
  line-height: 50px;
  border-radius: 10px 10px 0 0;
}
.mymsg img {
  height: 35px;
  width: 35px;
  line-height: 35px;
  margin: 10px 10px 15px 10px;
}
/* .el-button {
  width: auto;
  height: 25px;
  margin-top: 10px;
  font-size: 10px;
  line-height: 3px;
} */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bianji {
  z-index: 1;
  width: 800px;
  height: 500px;
  border: 1px solid white;
  position: absolute;
  top: 100px;
  left: 100px;
  background-color: white;
}

.modal-content {
  width: 500px;
  height: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.modal-content1 {
  width: 400px;
  height: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.modal-content input {
  width: 200px;
  height: 25px;
  border-radius: 2px;
  border: 1px solid rgb(179, 176, 176);
  margin-top: 7px;
  margin-left: 30px;
  width: 310px;
  margin-bottom: 10px;
}
.modal-content label {
  margin-left: 30px;
}
.input1 {
  margin-left: 50px;
}
.select2 {
  width: 120px;
  height: 25px;
  border-radius: 2px;
  border: 1px solid rgb(179, 176, 176);
  margin-top: 7px;
  margin-left: 20px;
}

.modal-content1 label {
  margin-left: 1px;
}

.modal-content1 input {
  width: 200px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid rgb(179, 176, 176);
  margin-top: 7px;
  margin-left: 20px;
  width: 260px;
  margin-bottom: 20px;
}
.modal2-content {
  width: 600px;
  height: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.content {
  width: 100%;
  margin-bottom: 15px;
  margin-left: 25px;
}

.center {
  width: 100%;
  margin-top: 20px;
  margin-left: 50px;
}
.center div {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: bolder;
}
.center div input {
  width: 85%;
  height: 30px;
}
.center div button {
  margin-left: 100px;
  background-color: rgb(255, 183, 0);
  border: 1px solid rgb(255, 183, 0);
  width: 80px;
  height: 30px;
  color: white;
  border-radius: 5px;
}
.btn2 {
  margin-top: 20px;
  margin-left: 250px;
  line-height: 5px;
}
.demo {
  display: flex;
  justify-content: space-between;
  width: 40%;
  height: 30px;
  margin-top: 10px;
}
.one {
  margin-top: 1%;
}

.Data_Content{
  width: 100%;
  display: flex;

}
.bt{
  display: inline-block;
  margin-left: 30%;
}
</style>