<template>
  <div >
    <div class="parent">
    <Banner></Banner>
    </div>
  <!-- <div class="box111"> -->
    <!-- 左侧导航栏 -->
    <div
      class="box"
      style="
        width: 10%;
        float: left;
        border: 0.1px solid #1c1f26;
        background-color: #1c1f26;
        height: 100vh;
        position: fixed;
  top: 60px;

left: 0;

 
   z-index: 2; 
      "
    >
      <!-- 定义一个大盒子 -->
      <div class="title_box clearFixed">
        <!-- 标题盒子,并设置一个为子级清除浮动的样式 -->
        <div class="text">全部分类</div>
        <!-- 设置文本内容,使用标题标签具有加粗功能 -->
      </div>
      <ul class="list_box">
        <!-- 无序列表标签 -->
        <li class="numb clearFixed" v-for="item in numbs" :key="item.id">
          <!-- 子级具有浮动,用的样式中有after,所以需要在父级设置清除浮动标签 -->

          <div class="text">{{ item.name }}</div>
          <!--  文本内容 -->
          <!-- <div class="submenu" v-for="sub in item.sub" :key="sub.id"> 
                            <a href="#" >{{sub.name}}</a>  
                        </div> -->
          <ul class="submenu">
            <li class="demo" v-for="sub in numbs.sub" :key="sub.id">
              <a href="#" style="font-size: 13px">{{
                sub.parentType.parent_id
              }}</a>
              <ul class="third">
                <li v-for="sub in sub.childTypeList" :key="sub.id">
                  <span @click="todetail111(sub)"
                    ><a href="#">{{ sub.type_id }}</a></span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  <!-- </div> -->
    <!-- 顶部三行选择搜索栏 和报价单 -->
    <div class="content" style="float: left; padding-left:300px">
      <!-- 第一行品牌名称栏 -->
      <div class="phonename">
        <span class="price1">全部品牌</span>
        <span v-for="item in phonename" :key="item"
          ><a href="#" @click="tosq(item.parent_id)">{{
            item.parent_id
          }}</a></span
        >
      </div>

      <!-- 另起一行写更多 -->
      <el-collapse
        @change="handleChange"
        style="width: 100%; padding-left: 12px"
      >
        <el-collapse-item title="更多品牌">
          <span v-for="phone in phonenames" :key="phone" style="width: 100%">
            <a href="#" style="margin-right: 10px; font-size: 14px">{{
              phone
            }}</a>
          </span>
        </el-collapse-item>
      </el-collapse>

      <!-- 第二行价格栏 -->
      <div class="price">
        <span class="price1">价格</span>
        <span><a href="#" @click="eq1()">0-999</a></span>
        <span><a href="#" @click="eq2()">1000-1999</a></span>
        <span><a href="#" @click="eq3()">2000-2999</a></span>
        <span><a href="#" @click="eq4()">3000-3999</a></span>
        <span class="price2"><a href="#" @click="eq5()">4000以上</a></span>
        ¥&nbsp;<input
          type="text"
          placeholder="最低价格"
          v-model="lowprice"
          style="width: 6%"
        />
        -&nbsp;¥&nbsp;<input
          type="text"
          placeholder="最高价格"
          v-model="highprice"
          style="width: 6%"
        />
        <input
          type="button"
          value="搜索"
          style="
            width: 50px;
            margin-left: 10px;
            background-color: #00b7f0;
            color: white;
            border: 1px solid #00b7f0;
            border-radius: 5px;
          "
          @click="eq()"
        />
      </div>

      <!-- 第三行输入名称搜索栏 -->
      <div class="price11">
        <span class="price1">名称</span>
        <input type="text" placeholder="请输入关键字" v-model="inputkey" />
        <input
          type="button"
          value="搜索"
          class="btncolor3"
          style="width: 50px"
          @click="eqgoods()"
        />
        <input
          type="button"
          value="新款"
          class="btncolor1"
          style="width: 50px"
          @click="eqgoods1()"
        />
        <input
          type="button"
          value="热销"
          class="btncolor1"
          style="width: 50px"
          @click="eqgoods2()"
        />
        <input
          type="button"
          value="特价"
          class="btncolor2"
          style="width: 50px"
          @click="eqgoods3()"
        />
        <input
          type="button"
          value="全部"
          class="btncolor3"
          style="width: 50px"
          @click="load()"
        />
      </div>

      <!-- 每天的报价单 -->
      <div>
        <h2>{{ dateFormat(newDate) }}报价单</h2>
        <div class="table">
          <div class="table1">
            <template>
              <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="commodity_name" label="名称" width="220">
                </el-table-column>
                <el-table-column prop="commodity_price" label="价格" width="70">
                </el-table-column>
                <el-table-column
                  prop="commodity_remark"
                  label="备注"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="account" label="数量" width="90">
                  <input type="text" style="width: 60px" v-model="account" />
                </el-table-column>
                <el-table-column prop="buy" label="购买" width="70">
                  <template slot-scope="scope">
                    <input
                      type="button"
                      value="加购"
                      style="width: 45px; border-radius: 5px"
                      @click="tocar(scope.row)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>

          <div class="table2">
            <template>
              <el-table :data="tableData1" border style="width: 100%">
                <el-table-column prop="commodity_name" label="名称" width="220">
                </el-table-column>
                <el-table-column prop="commodity_price" label="价格" width="70">
                </el-table-column>
                <el-table-column
                  prop="commodity_remark"
                  label="备注"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="account" label="数量" width="90">
                  <input type="text" style="width: 60px" v-model="account" />
                </el-table-column>
                <el-table-column prop="buy" label="购买" width="70">
                  <template slot-scope="scope">
                    <input
                      type="button"
                      value="加购"
                      style="width: 45px; border-radius: 5px"
                      @click="tocar(scope.row)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script scoped>
import Banner from "../../components/Banner/Banner.vue";
import axios from "axios";
export default {
  name: "Todayprice",
  components: { Banner },
  data() {
    return {
      isVisible: false,
      newDate: new Date(),
      account: "",
      lowprice: "",
      highprice: "",
      inputkey: "",
      activeIndex: "1",
      numbs: [
        {
          name: "智能手机区",
          sub: [
            //     {
            //     name: "苹果" ,
            //     third:[
            //         {
            //             name:'13系列'
            //         },
            //         {
            //             name:'手表系列'
            //         },
            //         {
            //             name:'15系列'
            //         },
            //         {
            //             name:'15Plus系列'
            //         },
            //         {
            //             name:'14Plus系列'
            //         },
            //         {
            //             name:'ipad蜂窝系列'
            //         },
            //         {
            //             name:'14系列'
            //         },
            //         {
            //             name:'14promax系列'
            //         },
            //         {
            //             name:'14pro系列'
            //         },
            //         {
            //             name:'ipad系列'
            //         },
            //         {
            //             name:'15pro系列'
            //         },
            //         {
            //             name:'笔记本系列'
            //         },
            //         {
            //             name:'15promax系列'
            //         }
            //     ]
            // }, {
            //     name: "华为" ,
            //     third:[
            //         {
            //             name:'优畅享系列'
            //         },
            //         {
            //             name:'P系列'
            //         },
            //         {
            //             name:'畅享系列'
            //         },
            //         {
            //             name:'Nzone系列'
            //         },
            //         {
            //             name:'NOVA系列'
            //         },
            //         {
            //             name:'麦芒系列'
            //         },
            //         {
            //             name:'MATE系列'
            //         },
            //         {
            //             name:'鼎桥TD-TEch'
            //         }
            //     ]
            // }, {
            //     name: "荣耀",
            //     third:[
            //         {
            //             name:'60系列'
            //         },
            //         {
            //             name:'80系列'
            //         },
            //         {
            //             name:'90系列'
            //         },
            //         {
            //             name:'70系列'
            //         },
            //         {
            //             name:'play系列'
            //         },
            //         {
            //             name:'X系列',
            //         },
            //         {
            //             name:'magic系列'
            //         },
            //         {
            //             name:'畅玩系列'
            //         }
            //     ]
            // },{
            //     name: "小米",
            //     third:[
            //         {
            //             name:'平板系列'
            //         },
            //         {
            //             name:'笔记本系列'
            //         },
            //         {
            //             name:'小米系列'
            //         },
            //         {
            //             name:'红米系列'
            //         }
            //     ]
            // },{
            //     name: "三星",
            //     third:[
            //         {
            //             name:'Z系列'
            //         },
            //         {
            //             name:'平板系列'
            //         },
            //         {
            //             name:'配件系列'
            //         },
            //         {
            //             name:'A系列'
            //         },
            //         {
            //             name:'手表系列'
            //         },
            //         {
            //             name:'W系列'
            //         },
            //         {
            //             name:'S系列'
            //         }
            //     ]
            // },{
            //     name: "OPPO",
            //     third:[
            //         {
            //             name:'FIND系列'
            //         },
            //         {
            //             name:'Reno系列'
            //         },
            //         {
            //             name:'A系列'
            //         },
            //         {
            //             name:'K系列'
            //         },
            //         {
            //             name:'真我系列'
            //         }
            //     ]
            // },{
            //     name: "VIVO",
            //     third:[
            //         {
            //             name:'X系列'
            //         },
            //         {
            //             name:'折叠系列'
            //         },
            //         {
            //             name:'S系列'
            //         },
            //         {
            //             name:'渠道系列'
            //         },
            //         {
            //             name:'IQOO系列'
            //         },
            //         {
            //             name:'Y系列'
            //         },
            //         {
            //             name:'pad系列'
            //         }
            //     ]
            // },{
            //     name: "一加",
            // },{
            //     name: "摩托罗拉",
            // },
          ],
        },
        {
          name: "融合生态链",
          sub: [
            {
              //     name: "苹果" ,
              //     third:[
              //         {
              //             name:'13系列'
              //         },
              //         {
              //             name:'手表系列'
              //         },
              //         {
              //             name:'15系列'
              //         }
              //     ]
              // }, {
              //     name: "华为" ,
              //     third:[
              //         {
              //             name:'13系列'
              //         },
              //         {
              //             name:'手表系列'
              //         },
              //         {
              //             name:'15系列'
              //         }
              //     ]
              // }, {
              //     name: "荣耀",
              //     third:[
              //         {
              //             name:'13系列'
              //         },
              //         {
              //             name:'手表系列'
              //         },
              //         {
              //             id:233,
              //             name:'15系列'
              //         }
              //     ]
              // },{
              //     name: "小米",
              //     third:[
              //         {
              //             name:'13系列'
              //         },
              //         {
              //             name:'手表系列'
              //         },
              //         {
              //             name:'15系列'
              //         }
              //     ]
              //
            },
          ],
        },
        {
          name: "手机配件区",
          sub: [
            {
              //     // name: "Web Development" ,
              //     // third:[
              //     //     {
              //     //         name:'bb'
              //     //     },
              //     //     {
              //     //         name:'cc'
              //     //     },
              //     //     {
              //     //         name:'ff'
              //     //     }
              //     // ]
              // }, {
              //     name: "Mobile Development" ,
              //     third: [
              //                     {
              //                         name: '裤子'
              //                     },
              //                     {
              //                         name: '裙子'
              //                     },
              //                     {
              //                         name: '牛仔'
              //                     }],
              // }, {
              //     name: "Graphic Design",
              //     third: [
              //                         {
              //                             name: '风衣'
              //                         },
              //                         {
              //                             name:' 大衣'
              //                         },
              //                         {
              //                             name: '羽绒服'
              //                         }]
            },
          ],
        },
        {
          name: "儿童手表区",
          sub: [
            //     {
            //     name: "Email" ,
            //     child:null
            // }, {
            //     name: "Phone",
            //     child:null
            // }, {
            //     name: "Address" ,
            //     child:null
            // },
          ],
        },
        {
          name: "老人机专区",
          sub: [
            //     {
            //     name: "Email" ,
            //     child:null
            // }, {
            //     name: "Phone",
            //     child:null
            // }, {
            //     name: "Address" ,
            //     child:null
            // },
          ],
        },
        {
          name: "物流费专区",
          sub: [
            //     {
            //     name: "Email" ,
            //     child:null
            // }, {
            //     name: "Phone",
            //     child:null
            // }, {
            //     name: "Address" ,
            //     child:null
            // },
          ],
        },
        {
          name: "电脑专卖区",
          sub: [
            //     {
            //     name: "Email" ,
            //     child:null
            // }, {
            //     name: "Phone",
            //     child:null
            // }, {
            //     name: "Address" ,
            //     child:null
            // },
          ],
        },
      ],

      phonename: [],
      phonenames: [

      ],
      tableData: {},

      tableData2: {},
      tableData1: {},
      usertel: "",
    };
  },
  created() {
    this.load();
    this.firstHalfList();
  },
  methods: {
    eqgoods() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByName",
        params: {
          commodity_name: this.inputkey,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eqgoods1() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByTag",
        params: {
          commodity_tag: 1,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eqgoods2() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByTag",
        params: {
          commodity_tag: 2,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eqgoods3() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByTag",
        params: {
          commodity_tag: 3,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: this.lowprice,
          highPrice: this.highprice,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq1() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: 0,
          highPrice: 999,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq2() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: 1000,
          highPrice: 1999,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq3() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: 2000,
          highPrice: 2999,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq4() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: 3000,
          highPrice: 3999,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    eq5() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityByPrice",
        params: {
          lowPrice: 4000,
          highPrice: 100000,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    tosq(res1) {
      console.log(res1);
      axios({
        method: "GET",
        url: "/api/commodity/commodityByType",
        params: {
          type: res1,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
    tocar(row) {
      this.usertel = localStorage.users;
      axios({
        method: "GET",
        url: "/api/shopcart/addCommodityToShopcart",
        params: {
          user_tel: this.usertel,
          commodity_id: row.commodity_id,
          commodity_amount: this.account,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("加购成功");
          this.account=""
        }
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showSubmenu(item) {
      item.showSubMenu = true;
    },
    hideSubmenu(item) {
      item.showSubMenu = false;
    },
    handleChange(val) {
      console.log(val);
    },

    // 时间格式化
    dateFormat() {
      var date = new Date();
      var year = date.getFullYear();
      // /*
      //  * 在日期格式中，月份是从0开始的
      //  * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
      //  * /
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let week = date.getDay(); // 星期

      // 拼接 时间格式处理
      return year + "年" + month + "月" + day + "日 ";
    },
    load() {
      axios({
        method: "GET",
        url: "/api/commodity/commodityList",
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
      axios({
        method: "GET",
        url: "/api/commodity/typeListLeft",
      }).then((res) => {
        this.numbs.sub = res.data;
      });
      axios({
        method: "GET",
        url: "/api/commodity/typeListTop",
      }).then((res) => {
        this.phonename = res.data;
        console.log(this.phonename);
      });
    },
    todetail111(sub) {
      console.log(sub);
      axios({
        method: "GET",
        url: "/api/commodity/commodityByType",
        params: {
          type: sub.type_id,
        },
      }).then((res) => {
        this.tableData2 = res.data;
        this.tableData = this.tableData2.slice(
          0,
          Math.ceil(this.tableData2.length / 2)
        );
        this.tableData1 = this.tableData2.slice(
          Math.ceil(this.tableData2.length / 2)
        );
      });
    },
  },
  // 挂载时间
  mounted() {
    let that = this;
    this.timer = setInterval(function () {
      that.newDate = new Date().toLocaleString();
    });
    // firstHalfList() {
    //   return this.list.slice(0, Math.ceil(this.list.length / 2));
    // },
  },
  // 销毁时清除计时器
  beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    firstHalfList() {
      this.tableData1 = this.tableData.slice(
        0,
        Math.ceil(this.tableData.length / 2)
      );
    },
    secondHalfList() {
      this.tableData = this.tableData.slice(
        Math.ceil(this.tableData.length / 2)
      );
    },
  },
};
</script>

  <style scoped>
.parent {
   position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
  .box111 {
 position: fixed;
  top: 60px;
  width: 100%;
left: 0;

 
   z-index: 2;  
}
/deep/ .el-collapse-item__header {
  color: #545c64;
  font-weight: bolder;
  font-size: 14px;
}

.content {
  width: 89%;
  height: auto;
  border: 1px solid white;

  /* box-shadow: 0px 0px 10px 10px rgb(207, 205, 205); */
}
.phonename {
  width: 100%;
  border-bottom: 1px solid rgb(214, 214, 214);
  height: 50px;
  display: flex;
}
.phonename span {
  width: auto;
  font-size: 14px;
  margin-left: 10px;
  padding-top: 15px;
  height: 30px;
  line-height: 20px;
}
a {
  text-decoration: none;
  color: black;
}

.span1 {
  background-color: #ffd04b;
}
.price {
  font-size: 14px;
  margin-top: 12px;
  border-bottom: 1px solid rgb(214, 214, 214);
  height: 35px;
}
.price span {
  margin-right: 10px;
  margin-left: 20px;
  line-height: 15px;
}
.price1 {
  color: #545c64;
  font-weight: bolder;
  margin-left: 0.9%;
  margin-right: 1%;
}
.price11 {
  font-size: 14px;
  margin-top: 12px;
  height: 35px;
}
.price2 {
  /* padding-right: 200px; */
  padding-right: 30%;
}
.input1 {
  width: 100px;
  margin-left: 100px;
}
h2 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ebebeb;
  height: 50px;
  border-left: 3px solid #fc3703;
  line-height: 50px;
}
.table {
  /* float: left; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1%;
  width: 92%;
  margin-left: 2%;
}
.table1,
.table2 {
  height: 100%;
  width: 100%;
}
.elbtn {
  height: 38px;
  /* margin-left: 30px; */
  /* line-height: 20px; */
}
/* el-dropdown是更多按钮的样式 */
.el-dropdown {
  margin-left: 30px;
}

.el-cascader-panel {
  max-width: 175px;
  float: left;
  height: 100%;
}
/* .el-cascader-menu__wrap {
          height: 100%
        
        } */
.btncolor1 {
  background-color: #fff000;
  margin-left: 10px;
  color: black;
  border: solid 1px #cfa42e;
  border-radius: 5px;
}
.btncolor2 {
  background-color: red;
  color: white;
  border: 1px solid rgb(150, 7, 7);
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
}
.btncolor3 {
  margin-left: 1%;
  border: 1px solid gray;
  color: white;
  background-color: #00b7f0;
  border-radius: 5px;
}

* {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}
列表重置
        
        /* S .box .title_box */
        .box {
         
  width: 100%;
  width: 120px;
  border: 1px solid rgb(206, 203, 203);
  border-radius: 4px;
  margin-left: 1px;
  margin-top: 1px;
} /* 给一个大盒子,将所有的内容都放进去 */
.box .title_box {
  width: 100%;
  background-color: #545c64;
  height: 60px;
  line-height: 60px;
  color: white;
} /* 规定标题盒子的大小和背景颜色 */
.box .title_box .logo {
  float: left;
  vertical-align: bottom;
  margin: 3px 0px 0px 10px;
} /* 设置一个左浮动,使可以一行显示,设置垂直对齐方式,使图片不会与以默认的基线对齐使下面与盒子边框有空隙,最后设置一下边距问题 */
/* S .box .title_box */

/* S- box .list_box */
.box .title_box .text {
  float: left;
  margin-left: 30%;
} /* 设置文本的浮动,使文本可以和logo一行显示,在设置一下边距问题 */
.box .list_box {
  position: relative;
} /* 后代使用定位,在这里是给绝对定位定义一个属性 */
.box .list_box .numb {
  width: 100%;
  color: white;
  background-color: #1c1f26;

  line-height: 1.5;
  height: 60px;
  line-height: 60px;
} /* 给列表设置一个宽度和背景色,并设置一个行高使他们之间有一些距离 */
.box .list_box .numb:hover {
  background-color: #431c15;
  color: white;
  border-right: 3px solid #fa3500;
} /* 给列表设置悬浮功能,使鼠标放在列表上可以改变字体和背景颜色 */
.box .list_box .numb .submenu {
  width: 500px;
  position: absolute;
  height: 420px;
  z-index: 1;
  background-color: white;
  left: 100%;
  display: none;

  top: 0px;
  border: 1px solid rgb(240, 235, 235);
  overflow-y: scroll;
  height: 330px;
} /* 在列表的子级中设置一个而二级菜单,设置好基本样式,并用定位设置固定的位置,然后隐藏起来, */
.box .list_box .numb:hover .submenu {
  display: block;
} /*  给列表标签设置悬浮功能,使鼠标悬浮在列表时,可以显示后代中的二级菜单 */
.box .list_box .logo {
  float: left;
  vertical-align: bottom;
  margin: 3px 0px 0px 10px;
} /* 设置每一行列表中的logo,使用浮动可以使logo和text在一行显示,同样设置垂直对齐方式,使其不会在底部有空隙,并设置一些边距 */
.box .list_box .text {
  float: left;
  margin-left: 27%;
} /* 设置列表文本的浮动,并设置一些外边距*/

.clearFixed::after {
  content: "";
  display: block;
  clear: both;
} /*  在标题盒子和列表盒子时都是用了浮动,所以要在这里使用清除浮动样式,这样才能使父级的高度由内容撑开,这里清除样式是使用after,after是在标签的最后加一个子标签,符合清除浮动中的在兄弟级标签最后插入的要求, after的content一定要写,但是可以为空,并且一定要设置成块级标签,最后设置清除属性clear,一般属性值都是both */
/* E- box .list_box */
.demo {
  /* width: auto; */
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  border-bottom: 1px solid rgb(240, 235, 235);
}
.demo a {
  text-decoration: none;
  color: rgb(97, 97, 97);
}
.third {
  display: flex;
  flex-wrap: wrap;
}
.third span {
  /* border: 1px solid black; */
  font-size: 10px;
  margin-left: 10px;
  border-left: 1px solid rgb(194, 191, 191);
  padding-left: 3px;
}
/* .allbrand{
          border: 1px solid #00b7f0;
          border-radius: 5px;
          background-color: #00b7f0;
          color: white;
          height: 60px;
          line-height: 60px;
        } */
</style>