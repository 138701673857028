  <template>
  <div class="cart_body">
    <Banner activeIndex="3"></Banner>

    <div class="content">
      <!-- 左侧菜单栏 -->
      <el-menu
        :default-openeds="['1', '3']"
        style="
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          width: 12%;
        "
        background-color="#1c1f26"
        text-color="#fff"
        active-text-color="#fff"
        :collapse-transition="false"
        :collapse="isCollapse"
        router
      >
        <el-menu-item index="/todayprice" style="background-color: #545c64">
          <template slot="title">
            <span class="bt" slot="title">全部分类</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/shoppingcart" style="background-color: #431a14">
          <template slot="title">
            <span class="bt" slot="title">购物车</span>
          </template>
        </el-menu-item>
        <el-menu-item index="/myorder" style="height: 1000px"> </el-menu-item>
      </el-menu>
      <!-- 表格 -->
      <div class="table">
        <table>
          <tr style="font-weight: bold">
            <!-- <td>序号</td> -->
            <td>商品名</td>
            <td>数量</td>
            <td>单价</td>
            <td>总价</td>
            <td>操作</td>
          </tr>
          <template v-for="item in info">
            <tr>
              <!-- <td v-text="item.shop_cart_id"></td> -->
              <td v-text="item.commodity_name"></td>
              <td>
                <div class="num">
                  <span
                    class="count"
                    style="border-right: 0"
                    @click="reduce(item)"
                    >-</span
                  >
                  <input
                    type="text"
                    v-model="item.commodity_amount"
                    style="width: 50px"
                    @change="updateDatabase(item)"
                  />
                  <span class="count" style="border-left: 0" @click="add(item)"
                    >+</span
                  >
                </div>
              </td>
              <td v-text="item.commodity_price"></td>
              <td v-text="item.total_price"></td>
              <td>
                <button class="delete" @click="del(item)">删除</button>
              </td>
            </tr>
          </template>
        </table>

        <!-- 合计 -->
        <div class="foot">
          <div class="foot_content">
            共：{{ this.len }}种商品，合计下单数量{{ num1 }}件，总价：￥<span
              style="
                color: red;
                font-size: 22px;
                font-weight: bold;
                margin-right: 10px;
              "
              >{{ this.num }}</span
            >
            <button
              class="btn"
              @click="tobuy"
              v-if="this.len > 0"
              style="
                background-color: #d12920;
                border: 1px solid #d12920;
                color: white;
              "
            >
              下单购买
            </button>
            <button
              class="btn"
              style="border: 1px solid #aaa"
              @click="qingkong"
            >
              清空购物车
            </button>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
</template>

<script>
import Banner from "../../components/Banner/Banner";
import axios from "axios";
export default {
  name: "ShoppingCart",
  components: { Banner },
  len: 0,
  num: 0,
  num1: 0,
  amount: 0,
  shop_cart_id: 0,
  data() {
    return {
      activeIndex: "3",
      usertel: "",
      info: [],
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.usertel = localStorage.users;
      axios({
        method: "GET",
        url: "/api/shopcart/shopcartList",
        params: {
          user_tel: this.usertel,
        },
      }).then((res) => {
        this.info = res.data[0].shopcartlist;
        this.len = this.info.length;
        let sum = 0;
        let sum1 = 0;
        this.info.forEach((item) => {
          //遍历prodAllPrice这个字段，并累加
          sum += item.total_price;
          sum1 += item.commodity_amount;
        });
        //返回
        this.num = sum;
        this.num1 = sum1;
      });
    },
    del(item) {
      this.shop_cart_id = item.shop_cart_id;
      axios({
        method: "GET",
        url: "/api/shopcart/deleteCommodityInShopcart",
        params: {
          shop_cart_id: this.shop_cart_id,
        },
      }).then((res) => {
        console.log(res);
        this.load();
      });
    },
    qingkong() {
      this.usertel = localStorage.users;
      axios({
        method: "GET",
        url: "/api/shopcart/deleteAllCommodityInShopcart",
        params: {
          user_tel: this.usertel,
        },
      }).then((res) => {
        console.log(res);
        this.load();
      });
    },
    tobuy() {
      this.$router.push("/SubOrder");
    },
    reduce(item) {
      console.log(item);
      this.usertel = localStorage.users;
      this.amount = item.commodity_amount - 1;
      if (this.amount > 0) {
        this.shop_cart_id = item.shop_cart_id;
        console.log(this.shop_cart_id);
        axios({
          method: "GET",
          url: "/api/shopcart/reduceCount",
          params: {
            shop_cart_id: this.shop_cart_id,
          },
        }).then((res) => {
          this.load();
        });
      } else {
        this.shop_cart_id = item.shop_cart_id;
        console.log(this.shop_cart_id);
        axios({
          method: "GET",
          url: "/api/shopcart/deleteCommodityInShopcart",
          params: {
            shop_cart_id: this.shop_cart_id,
          },
        }).then((res) => {
          this.load();
        });
      }
    },
    add(item) {
      console.log(item);
      this.usertel = localStorage.users;
      this.amount = item.commodity_amount + 1;
      if (this.amount > 0) {
        this.shop_cart_id = item.shop_cart_id;
        console.log(this.shop_cart_id);
        axios({
          method: "GET",
          url: "/api/shopcart/addCount",
          params: {
            shop_cart_id: this.shop_cart_id,
          },
        }).then((res) => {
          this.load();
        });
      }
    },
    updateDatabase(item) {
      // 在这里编写将输入框的值直接上传到数据库的逻辑
      // 可以通过axios等方式发送请求
      console.log(item);
      this.usertel = localStorage.users;
      this.amount = item.commodity_amount + 1;
      if (this.amount > 0) {
        this.shop_cart_id = item.shop_cart_id;
        axios({
          method: "GET",
          url: "/api/shopcart/deleteCommodityInShopcart",
          params: {
            shop_cart_id: this.shop_cart_id,
          },
        }).then((res) => {});
        axios({
          method: "GET",
          url: "/api/shopcart/addCommodityToShopcart",
          params: {
            user_tel: this.usertel,
            commodity_id: item.commodity_id,
            commodity_amount: item.commodity_amount,
          },
        }).then((res) => {
          this.load();
        });
      } else {
        this.shop_cart_id = item.shop_cart_id;
        axios({
          method: "GET",
          url: "/api/shopcart/deleteCommodityInShopcart",
          params: {
            shop_cart_id: this.shop_cart_id,
          },
        }).then((res) => {
          this.load();
        });
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.cart_body {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
}
.table {
  width: 100%;
  margin: 20px 50px 20px 50px;
  background-color: white;
}

table {
  width: 100%;
  text-align: center;
}
table tr {
  height: 50px;
}
table tr td {
  border-top: 2px solid #ccc;
}

.delete {
  width: 50px;
  height: 30px;
  color: white;
  background-color: #ab1d22;
  border: 1px solid #ab1d22;
  border-radius: 5px;
}
.foot {
  display: flex;
  justify-content: flex-end;
}
.foot_content {
  margin-top: 15px;
  margin-right: 15px;
}
.btn {
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
  font-size: 15px;
}
.content {
  display: flex;
  justify-content: flex-start;
}
.num {
  width: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.count {
  display: inline-block;
  width: 30px;
  border: 1px solid #a7a7a7;
}
.bt {
  display: inline-block;
  margin-left: 30%;
}
</style>