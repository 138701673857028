import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import router from './router'
import ElementUI from 'element-ui'
// 引入ElementUI全部样式
import 'element-ui/lib/theme-chalk/index.css';

import SIdentify from './components/SIdentify/SIdentify'
Vue.use(SIdentify)

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(ElementUI)
axios.defaults.baseURL = ''
new Vue({
  el:'#app',
  render: h => h(App),
  router:router,


})
