<template>
  <div class="box">
    <!-- 导航栏 -->
    <div class="top">
      <div class="top_center">
        <div class="top_center_left">
          <img src="./images/logo-mi2.png" />
          <span
            style="
              display: inline-block;
              height: 40px;
              margin-left: 10px;
              margin-bottom: 10px;
              padding-left: 10px;
              line-height: 45px;
              font-size: 25px;
              border-left: 1px solid #222;
            "
            >欢迎登录！</span
          >
        </div>

        <div class="top_center_right">
          <span>
            <img src="./images/china.jpg" />
            <!-- 选择语言 -->
            <span style="display: inline-block; width: 50px">语言：</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <i style="width: 30px; height: 50px" class="el-icon-headset"></i>
            <router-link to="">在线客服</router-link>
          </span>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="center">
      <!-- 轮播图 -->
      <div class="slideshow" style="display: inline-block">
        <img v-for="(v, i) in list" :key="i" :src="v" alt="" v-show="n == i" />
      </div>

      <!-- 用户登录 -->
      <div class="login">
        <div class="login_top">
          <span>用户登录</span>
          <div class="redio">
            <el-radio v-model="radio" label="1">
              <i class="el-icon-s-management"></i>
              标准版
            </el-radio>
            <el-radio v-model="radio" label="2">
              <i class="el-icon-s-order"></i>
              简约版
            </el-radio>
          </div>
        </div>
        <!-- 登录表单 -->
        <form action="">
          <div style="margin-top: 10px;">
            <img src="./images/username.png" class="login_icon" />
            <input
              type="text"
              placeholder=" 请输入登录账号"
              class="info"
              v-model="user"
              @keyup.enter.native="handleSearchMember"
            />
          </div>
          <div>
            <img src="./images/password.png" class="login_icon" />
            <input
              type="password"
              placeholder=" 请输入登录密码"
              class="info"
              v-model="password"
              @keyup.enter.native="handleSearchMember"
            />
          </div>
          <!-- 验证码 -->
          <div>
            <img src="./images/ver.png" class="login_icon" />
            <el-form
              :model="loginForm"
              :rules="loginFormRules"
              ref="FormRef"
              label-position="top"
              style="display:inline-block; width: 280px;height: 45px;margin: 10px 0 15px 0;border:1px solid #ffffff;"
            >
              <el-form-item
                prop="pictureCode"
                style="width: 280px;"
              >
                <el-input
                  placeholder="请输入验证码"
                  v-model="loginForm.pictureCode"
                  autocomplete="off"
                  class="pictureCode"
               
                  @keyup.enter.native="handleSearchMember"
                >
                  <template slot="append">
                    <div style="border: none" @click="refreshCode">
                      <!-- 3. 使用 identify 组件 -->
                      <picture-identify
                        :identifyCode="loginForm.identifyCode"
                      ></picture-identify>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form_foot">
            <span><input type="checkbox" />记住手机号</span>
            <span @click="register" style="color: red">免费注册</span>
            <!-- <router-link active-class="active" to="/register">免费注册</router-link> -->
          </div>
          <!-- <router-link class="submit" to="/todayprice">登录</router-link> -->
          <div
            class="submit"
            @click="toLogin"
            @keyup.enter.native="handleSearchMember"
          >
            登录
          </div>
        </form>
      </div>

      <!-- 商家信息 -->
      <div class="merchantInfo">
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
    <!-- <div>
      <el-button type="primary">查询</el-button>
      <el-button type="info">重置</el-button>
      <el-button>关闭</el-button>
      <el-button type="success">确定</el-button>
    </div>
  <div style="padding-top: 30px;">
    <el-button type="primary">重新设置</el-button>
    <el-button type="primary">扫描答案</el-button>
    <el-button type="primary">导入答案</el-button>
    <el-button type="primary">快速设分</el-button>
    <el-button type="primary">导出答案</el-button>
    <el-button type="primary">保存</el-button>
  </div>
  <div style="padding-top: 30px;">
    <el-button>回到首页</el-button>
  </div> -->
  <footer>
      <!-- Your footer content goes here -->
      <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2023024592号-2</a></p>
    </footer>
  </div>
  
</template>
    
    <script>
import PictureIdentify from "../../components/SIdentify/SIdentify.vue";
import axios from "axios";
export default {
  name: "Login",
  data() {
    var checkPictureCode = (rules, value, callback) => {
      if (value == this.loginForm.identifyCode) {
        // return callback();
        this.code = 1;
      } else {
        callback(new Error("验证码错误"));
        this.code = 0;
      }
    };

    return {
      code: 0,
      user: "",
      password: "",
      radio: "1",
      loginForm: {
        pictureCode: "", //输入input框的验证码
        identifyCode: "", //存储的图片验证码
      },
      // 验证规则
      loginFormRules: {
        pictureCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
          { validator: checkPictureCode, trigger: "blur" },
        ],
      },
      options: [
        {
          value: "选项1",
          label: "中文",
        },
        {
          value: "选项2",
          label: "英文",
        },
      ],
      value: "选项1",
      n: 0,
      list: [
        require("../Login/images/1.jpg"),
        require("../Login/images/2.jpg"),
        require("../Login/images/3.jpg"),
        require("../Login/images/4.png"),
        require("../Login/images/5.png"),
      ],
    };
  },
  components: { PictureIdentify },
  mounted() {
    this.refreshCode();
    this.play();
    const that = this;
    document.addEventListener("keydown", that.handleWatchEnter);
  },
  methods: {
    toLogin() {
      if (this.code === 1) {
        axios({
          method: "GET",
          url:
            "/api/user/gologin?user_tel=" +
            this.user +
            "&password=" +
            this.password,
        }).then((res) => {
          if (res.data.code === 200) {
            localStorage.setItem("user", JSON.stringify(res.data)); // 存储用户信息到浏览器
            localStorage.setItem("users", this.user);
            this.$message.success("登录成功");
            this.$router.push("/todayprice");
          } else {
            this.$message.error(res.data.msg);
          }
          console.log(res);
        });
      } else {
        this.$message.error("请输入正确的验证码");
      }
    },

    autoPlay() {
      this.n++;
      if (this.n === this.list.length) {
        this.n = 0;
      }
    },
    play() {
      setInterval(this.autoPlay, 2000);
    },
    // 获取随机图片验证码
    refreshCode() {
      var numCode = "";
      for (var i = 0; i < 4; i++) {
        numCode += Math.floor(Math.random() * 10);
      }
      this.loginForm.identifyCode = numCode;
    },
    register() {
      this.$router.push("/Register");
    },
    handleWatchEnter(e) {
      let key = window.event ? e.keyCode : e.which;
      console.log(key);
      if (key === 13) {
        // 这里执行相应的行为动作
        console.log(this.code);
        if (this.code === 1) {
          axios({
            method: "GET",
            url:
              "http://218.28.30.172:10705/user/gologin?user_tel=" +
              this.user +
              "&password=" +
              this.password,
          }).then((res) => {
            if (res.data.code === 200) {
              localStorage.setItem("user", JSON.stringify(res.data)); // 存储用户信息到浏览器
              localStorage.setItem("users", this.user);
              this.$message.success("登录成功");
              this.$router.push("/todayprice");
            } else {
              this.$message.error(res.data.msg);
            }
            console.log(res);
          });
        } else {
          this.$message.error("请输入正确的验证码");
        }
      }
    },
  },
};
</script>
    
    <style scoped>
* {
  margin: 0px;
  padding: 0px;
  border: none;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5; /* 你可以根据需要修改背景色 */
  padding: 10px 0;
}
.top {
  width: 100%;
  height: 60px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.top_center {
  display: flex;
  width: 1100px;
  margin: auto;
  padding: 5px;
}
.top_center img {
  width: 40px;
  height: 40px;
  line-height: 50px;
}
.top_center_left {
  width: 550px;
  display: flex;
}
.top_center_right {
  display: flex;
  justify-content: flex-end;
  width: 550px;
}
.top_center_right img {
  width: 20px;
  height: 20px;
}
.center {
  margin: auto;
  width: 1100px;
  display: flex;
  justify-content: space-around;
}
li {
  list-style: none;
}
.slideshow {
  position: relative;
  width: 720px;
  height: 375px;
  margin: 20px auto;
}
.slideshow img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.login_top {
  margin: 20px auto;
  margin-bottom: 0;
  font-size: 18px;
  width: 350px;
  height: 60px;
  display: flex;
  line-height: 60px;
  justify-content: space-around;
  border: 1px solid #ccc;
  border-bottom: 2px solid red;
  padding: 0 5px;
  font-weight: bold;
}
.redio {
  width: 240px;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
}
.info {
  margin: 10px auto;
  width: 280px;
  height: 45px;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
}
.form_foot {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
.submit {
  width: 330px;
  height: 40px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  background-color: #c23c31;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  margin: 20px auto;
}
.login_icon {
  width: 40px;
  height: 40px;
  padding: 3px;
  box-sizing: border-box;
  margin: 0 auto;
}

.pictureCode >>> .el-input-group__append {
  padding: 0;
}
.pictureCode >>> .s-canvas canvas {
  margin-top: 0;
}

</style>